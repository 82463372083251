<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="4"
          class="mb-2"
        >
          <h3>Account number or email:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by account number or email"
                @keyup.enter="advancedFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="advancedFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          class="mb-2"
        >
          <h3>Account tags:</h3>
          <tag-tree-select v-model="advancedFilter.accountTags" />

          <h3 class="mt-1">
            User tags:
          </h3>
          <tag-tree-select
            v-model="advancedFilter.userTags"
          />
        </b-col>

        <b-col
          lg="2"
          class="mb-2"
        >
          <h3>Convert currency</h3>
          <b-form-group>
            <label>Withdrawal amount</label>
            <b-form-input
              v-model.number="withdrawalAmount"
              disabled
            />
            <label>Conversion rate {{ selectedCurrency || '-' }}  to USD</label>
            <b-form-input
              v-model="rate"
            />
            <label>Result USD</label>
            <b-form-input
              v-model="resultUsd"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <div class="d-flex flex-wrap">
            <b-badge
              v-for="(item, key) in withdrawalCounts"
              :key="key"
              :variant="`light-${withdrawalStateVariants[item.state]}`"
              class="m-50"
            >
              {{ item.state }}: {{ item.withdrawals_count }} | {{ formatCurrency(item.withdrawals_sum, 'USD') }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <data-table
      :module-name="'withdrawalsApprovedList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="withdrawal_datetime"
      :sort-desc="true"
      :actions="actions"
      deletable
      @edit="onEdit"
      @confirm="onConfirm"
      @removeCertificate="onRemoveCertificate"
    >
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              ({{ data.item.user.country.name }})
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account.account_id } }">
              {{ data.item.account.account_id }} ({{ accountTypes[data.item.account.account_type_name] }} - {{ data.item.account.server.platform.id }})
            </b-link>
            <risk-score-badge
              v-if="data.item.account.risk_score"
              class="mx-1"
              :score-results="data.item.account.risk_score_results"
              :score="data.item.account.risk_score"
            />
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.tags"
              :extras-tags="getAccountExtraTags(data.item.account)"
            />
            <!--            <span v-if="data.item.account.profit_split === 90">UNFAZED</span>-->
          </b-col>
        </b-row>
      </template>

      <template #cell(current_balance)="data">
        <span v-if="!data.item.completed">{{ formatCurrency(data.item.account.balance - data.item.account.deposit, data.item.account.currency.label) }}</span>
        <span v-else>Withdrawal completed</span>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.account.deposit, data.item.account.currency.label) }}
      </template>

      <template #cell(captured_balance)="data">
        {{ formatCurrency(data.item.amount, data.item.account.currency.label) }}
      </template>

      <template #cell(amount)="data">
        <b-button
          v-if="['EUR', 'GBP'].includes(data.item.account.currency.label)"
          size="sm"
          variant="outline-primary"
          class="m-25"
          @click="setConversion(data.item.profit_share_amount, data.item.account.currency.label)"
        >
          to USD
        </b-button>
        {{ formatCurrency(data.item.profit_share_amount, data.item.account.currency.label) }}
        <b-badge
          v-if="data.item.account.withdrawal_count === 0 || (data.item.account.withdrawal_count === 1 && data.item.withdrawal_count === 1)"
          variant="light-primary"
        >
          {{ getRootAccount(data.item.account, data.item.amount, data.item.profit_share, data.item.account.currency.label) }}
        </b-badge>
      </template>

      <template #cell(profit_share)="data">
        {{ data.item.profit_share }}%
      </template>

      <template #cell(withdrawal_datetime)="data">
        {{ formatDateTime(data.item.withdrawal_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge
          :variant="`light-${withdrawalStateVariants[data.item.state]}`"
        >
          {{ data.item.state }}
        </b-badge>

        <span
          v-clipboard:copy="getWithdrawalInfo(data.item)"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
          v-b-tooltip.hover.right="'Copy a basic payout info'"
          class="font-weight-bolder m-25"
        >
          <feather-icon
            class="mr-1"
            icon="CopyIcon"
            size="25"
          />
        </span>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(confirmed_at)="data">
        {{ formatDateTime(data.item.confirmed_at) }}
      </template>
    </data-table>
    <b-modal
      id="withdrawalModal"
      :title="title"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <withdrawal-form
        :withdrawal="withdrawalEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import {
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCol,
  BRow,
  BBadge,
  BCard,
  BLink,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import { isEmpty } from 'lodash'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import copyToClipboard from '@/mixins/copyToClipboard'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { ACCOUNTS_SHORT, ACCOUNT_PHASES } from '@/enums/accounts'
import { STATE_VARIANTS } from '@/enums/withdrawals'
import DataTable from '@/components/ui/tables/DataTable.vue'
import WithdrawalForm from '@/views/withdrawal/components/WithdrawalForm.vue'
import WithdrawalAPI from '@/api/withdrawal'
import TagsList from '@/components/page/TagsList.vue'
import TagTreeSelect from '@/components/ui/tables/TagTreeSelect.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Confirm',
    icon: 'CheckCircleIcon',
    emit: 'confirm',
  },
  {
    text: 'Remove certificate',
    icon: 'TrashIcon',
    emit: 'removeCertificate',
  },
]

const fields = [
  {
    key: 'user',
    sortable: false,
  },
  {
    key: 'account',
    sortable: false,
  },
  {
    key: 'deposit',
    label: 'Deposit',
  },
  {
    key: 'current_balance',
    label: 'Current balance on account',
    sortable: true,
  },
  {
    key: 'captured_balance',
    label: 'Captured balance',
    sortable: true,
  },
  {
    key: 'amount',
    label: 'Profit share $',
    sortable: true,
  },
  {
    key: 'profit_share',
    label: 'Profit share %',
    sortable: true,
  },
  {
    key: 'withdrawal_datetime',
    sortable: true,
  },
  {
    key: 'state',
    sortable: false,
  },
  {
    key: 'platform',
    sortable: false,
  },
  {
    key: 'confirmed_at',
    sortable: true,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
]

export default {
  components: {
    TagTreeSelect,
    WithdrawalForm,
    DataTable,
    TagsList,
    RiskScoreBadge,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCol,
    BRow,
    BBadge,
    BCard,
    BLink,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [copyToClipboard],
  data() {
    return {
      withdrawalCounts: [],
      withdrawalAmount: 0,
      rate: 0,
      selectedCurrency: null,
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      withdrawalStateVariants: STATE_VARIANTS,
      fields,
      advancedFilter: {
        search: '',
        state: ['approved by support'],
      },
      search: '',
      actions,
      title: 'Create withdrawal',
      withdrawalEdit: null,
      userTags: [],
      accountTags: [],
    }
  },
  computed: {
    ...get('withdrawalsApprovedList', ['filters']),
    resultUsd() {
      return this.withdrawalAmount * this.rate
    },
  },
  beforeMount() {
    if (!isEmpty(this.filters)) {
      this.advancedFilter = { ...this.filters }
    }
  },
  mounted() {
    WithdrawalAPI.withdrawalsCounts().then(response => {
      this.withdrawalCounts = response
    })
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getAccountExtraTags,
    setConversion(amount, currency) {
      this.withdrawalAmount = amount
      this.selectedCurrency = currency
    },
    getRootAccount(account, amount, profitSplit, currency) {
      if (account.parent === null) {
        const createdAt = new Date(account.createdAt)
        const dateToRefundableFee = new Date('2024-02-16T19:00:00+00:00')
        const dateTo5percBonus = new Date('2024-07-30T15:00:00+00:00')
        if (createdAt > dateTo5percBonus) return 'No refundable fee or bonus.'
        if (createdAt < dateToRefundableFee) return account.order === null ? '+ Original order fee not found' : `+ order fee ${formatCurrency(account.order.price, 'USD')}`

        return `+ 5% profit split. Total ${formatCurrency(amount * ((profitSplit + 5) / 100), currency)}`
      }
      return this.getRootAccount(account.parent, amount, profitSplit, currency)
    },
    onEdit(item) {
      this.title = 'Edit withdrawal'
      this.withdrawalEdit = item
      this.$bvModal.show('withdrawalModal')
    },
    onEditSubmit() {
      this.$bvModal.hide('withdrawalModal')
    },
    onCreateWithdrawal() {
      this.title = 'Create withdrawal'
      this.withdrawalEdit = null
      this.$bvModal.show('withdrawalModal')
    },
    onConfirm(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal will be approved!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm',
      }).then(result => {
        if (result.isConfirmed) {
          WithdrawalAPI.confirmWithdrawal(item.id).then(() => {
            this.$store.dispatch('withdrawalsApprovedList/reloadAll')
          })
        }
      })
    },
    onRemoveCertificate(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal certificate will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          WithdrawalAPI.removeWithdrawalCertificate(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Certificate was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
    getWithdrawalInfo(item) {
      const userTagsArr = item.user.tags.map(i => i.tag.name)
      const userExtraArr = getUserExtraTags(item.user).map(i => (typeof i === 'object' ? i.name : i))

      const userTags = userTagsArr.join(', ')
      const userExtra = userExtraArr.join(', ')

      const accTagsArr = item.account.tags.map(i => i.tag.name)
      const accExtraArr = getAccountExtraTags(item.account).map(i => (typeof i === 'object' ? i.name : i))

      const accTags = accTagsArr.join(', ')
      const accExtra = accExtraArr.join(', ')
      return `${item.user.email}, ${item.user.firstName} ${item.user.lastName} (${item.user.country.name}) | ${item.platform}. \nUser tags: ${userTags}, ${userExtra} \nAccount tags: ${accTags}, ${accExtra}`
    },
  },
}
</script>
